.interior-top {
    width: 100%;
    height: 100vh;
    position: relative;
    text-align: center;
}

.interior-pr {
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 0 20px;
    box-sizing: border-box;
}

h2.interior-title {
    width: 100%;
    position: relative;
    margin-bottom: 40px;
    font-size: 4rem;
    color: #000;
}

p.interior-message {
    width: 100%;
    position: relative;
    font-size: 1.5rem;
    color: #000;
}

.interior-blocks {
    width: 100%;
    max-width: 1200px;
    min-height: 1000px;
    display: block;
    margin: 0 auto;
    text-align: center;
    text-align: left;
}

.interior-item {
    display: inline-block;
    position: relative;
    background-color: #000;
    border-radius: 20px;
    margin-bottom: 30px;
    overflow: hidden;
}

.interior-item-img {
    width: 100%;
    position: relative;
}

.interior-popup-img {
    position: relative
}

h3.interior-item-name {
    width: 100%;
    position: relative;
    text-align: center;
    font-size: 1rem;
    color: #fff;
    line-height: 1;
    margin: 10px 0;
    z-index: 10;
}

@media screen and (max-width: 550px) {
    h2.interior-title {
        font-size: 2.5rem;
    }

    p.interior-message {
        font-size: 1.25rem;
    }
}