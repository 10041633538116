.details {
    width: 100%;
    max-width: 1200px;
    position: relative;
    display: block;
    margin: 0 auto;
    z-index: 200;
}

.details-item {
    width: 880px;
    max-width: 100%;
    display: inline-block;
    position: relative;
    margin: 80px 0;
    background-color: #f6f8f8;
}

.details-block.left {
    width: 50%;
    position: relative;
    float: left;
    padding: 40px;
    box-sizing: border-box;
}

.details-block.right {
    width: 50%;
    position: relative;
    float: right;
    padding: 40px;
    box-sizing: border-box;
}

.details-block.left img.details-img {
    width: 120%;
    display: block;
    position: absolute;
    left: -20%;
}

.details-block.right img.details-img {
    width: 120%;
    display: block;
    position: absolute;
    right: -20%;
}

.details-block img.details-img-resp {
    width: 100%;
    position: relative;
    display: none;
}

h3.details-title {
    width: 100%;
    position: relative;
    font-family: 'Noto Serif JP', serif;
    font-size: 2rem;
    font-weight: 700;
    color: #000;
    margin: 0 0 20px;
}

.details-description {
    width: 100%;
    position: relative;
    margin: 40px 0 0;
    font-size: 1.25rem;
    color: #000;
    text-align: justify;
    box-sizing: border-box;
}

.details-links {
    width: 100%;
    display: block;
    position: relative;
    margin: 40px 0;
    text-align: center;
}

a.details-link-btn {
    display: inline-block;
    padding: 5px 40px;
    margin: 5px;
    border: 2px solid #000;
    border-radius: 40px;
    background-color: #fff;
    font-size: 1.15rem;
    font-weight: bold;
    color: #000;
    transition: .2s;
}

a.details-link-btn:hover {
    border: 2px solid #fff;
    background-color: #000;
    color: #fff;
}

.details-number {
    display: inline-block;
    padding: 5px 40px;
    margin: 5px;
    font-size: 1.25rem;
    font-weight: bold;
    color: #000;
    transition: .2s;
}

.details-number a {
    color: #000;
}

.details-number a:hover {
    opacity: .8;
}

.details-address {
    width: 100%;
    position: relative;
    margin-top: 40px;
    text-align: center;
    font-size: 1.25rem;
    font-weight: bold;
    color: #000;
}

@media screen and (max-width: 1060px) {
    .details-block.left img.details-img {
        width: 100%;
        left: 0;
    }
    
    .details-block.right img.details-img {
        width: 100%;
        right: 0;
    }
}

@media screen and (min-width: 767px) and (max-width: 900px) {
    h3.details-title.details-contact-title {
        font-size: 1.7rem
    }
}

@media screen and (max-width: 767px) {
    .details-item {
        min-height: auto;
    }

    .details-block.left, .details-block.right {
        width: 100%;
        padding: 40px 30px;
    }

    .details-block.img {
        display: none;
    }

    .details-block img.details-img-resp {
        display: block;
    }
}