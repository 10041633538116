#show {
    display: block;
}

#hidden {
    display: none;
}

.swiper {
    width: 80vw;
    height: 80vh;
    text-align: center;
}
  
.swiper__slide {
    width: 100%;
    height: 100%;
}
  
.swiper__slide img {
    width: auto;
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}