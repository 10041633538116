.history {
    width: 100%;
    height: calc(4900px + 100vh);
    position: relative;
    display: block;
    margin: 0 auto;
    background-color: #000;
}

.history-top {
    width: 100%;
    position: fixed;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    padding: 0 20px;
    text-align: center;
    box-sizing: border-box;
}

h2.history-title {
    width: 100%;
    position: relative;
    margin-bottom: 40px;
    font-size: 4rem;
    color: #fff;
}

p.history-message {
    width: 100%;
    position: relative;
    font-size: 1.5rem;
    color: #fff;
}

.histories {
    width: 100%;
    height: 100vh;
    position: fixed;
    padding: 20px;
    box-sizing: border-box;
    opacity: 0;
}

.timeline {
    width: 4px;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 50px;
    background-color: #fff;
    opacity: 0;
}

.point {
    width: 20px;
    height: 20px;
    position: fixed;
    background-color: #000;
    transform: translate(-8px, -50%);
    border: 2px solid #fff;
    border-radius: 50%;
    box-sizing: border-box;
}

.point1 {
    top: 12.5%;
}

.point2 {
    top: 25%;
}

.point3 {
    top: 37.5%;
}

.point4 {
    top: 50%;
}

.point5 {
    top: 62.5%;
}

.point6 {
    top: 75%;
}

.point7 {
    top: 87.5%;
}

.point-active {
    width: 40px;
    height: 40px;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
}

.point1 .point-active {
    opacity: 1;
}

.history-block {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0
}

.history-img {
    width: calc(100% - 104px);
    height: calc(100vh - 150px);
    position: relative;
    display: block;
    float: right;
    text-align: center;
}

.history-img img {
    width: auto;
    max-width: 85%;
    max-height: 85%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);;
}

.history-text {
    width: calc(100% - 104px);
    height: 150px;
    position: relative;
    display: block;
    float: right;
    text-align: center;
}

.history-text p {
    font-size: 1.5rem;
    color: #fff;
}

.h1 {
    position: absolute;
    top: 1100px;
    left: 0;
}

.h2 {
    position: absolute;
    top: 1750px;
}

.h3 {
    position: absolute;
    top: 2350px;
}

.h4 {
    position: absolute;
    top: 2950px;
}

.h5 {
    position: absolute;
    top: 3550px;
}

.h6 {
    position: absolute;
    top: 4250px;
}

.h7 {
    position: absolute;
    top: 4850px;
}

@media screen and (max-width: 650px) {
    h2.history-title {
        font-size: 2.5rem;
    }

    p.history-message {
        font-size: 1.25rem;
    }
}

@media screen and (max-width: 500px) {
    .history-text p {
        font-size: 1.05rem;
    }
}

@media screen and (max-width: 450px) {
    h2.history-title {
        font-size: 2rem;
    }

    p.history-message {
        font-size: 1.15rem;
    }
}