.spec-tabs {
    width: 100%;
    max-width: 1200px;
    margin: 80px auto 40px;
    padding: 0 20px;
    background-color: #fff;
    box-sizing: border-box;
}
  
.tab_item {
    width: calc(100%/2);
    padding: 10px 0;
    border: 3px solid #000;
    border-radius: 40px;
    background-color: #fff;
    font-size: 1.25rem;
    line-height: 1;
    text-align: center;
    color: #000;
    display: block;
    float: left;
    text-align: center;
    transition: all 0.2s ease;
    box-sizing: border-box;
    cursor: pointer;
}
.tab_item:hover {
    opacity: 0.75;
}
  
input[name="tab_item"] {
    display: none;
}
  
.tab_content {
    display: none;
    padding: 40px 40px 0;
    clear: both;
    overflow: hidden;
}
  
#shape:checked ~ #shape_content,
#radius:checked ~ #radius_content {
    display: block;
}
  
.spec-tabs input:checked + .tab_item {
    background-color: #000;
    color: #fff;
}

@media screen and (max-width: 500px) {
    .tab_item {
        font-size: 1rem;
    }

    .tab_content {
        padding: 40px 20px 0
    }
}