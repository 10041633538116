.top-bg-slider {
    width: 100%;
    height: 100vh;
    position: fixed;
    overflow: hidden;
}

.bg-slider {
    width: 100%;
    height: 100%;
    position: relative;
}

.bg-slider:first-of-type {
    animation: loop 80s -40s linear infinite;
}

.bg-slider:last-of-type {
    animation: loop2 80s -80s linear infinite;
}

.bg1 {
    width: 20vw;
    position: absolute;
    top: 10%;
    left: 28%;
}

.bg2 {
    width: 18vw;
    position: absolute;
    top: 20%;
    right: 4%;
}

.bg3 {
    width: 16vw;
    position: absolute;
    top: 40%;
    left: 55%;
}

.bg4 {
    width: 18vw;
    position: absolute;
    top: 60%;
    left: 15%;
}

.bg5 {
    width: 16vw;
    position: absolute;
    top: 85%;
    right: 8%;
}
 
@keyframes loop {
    0% {
      transform: translateY(100%);
    }
    to {
      transform: translateY(-100%);
    }
}
  
@keyframes loop2 {
    0% {
      transform: translateY(0);
    }
    to {
      transform: translateY(-200%);
    }
}
  
@media screen and (max-width: 500px) {
    .slider-img:first-of-type {
      animation: loop 70s -35s linear infinite;
    }
  
  .slider-img:last-of-type {
      animation: loop2 70s -70s linear infinite;
    }
}
  
  