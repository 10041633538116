.product-top {
    width: 100%;
    height: 100vh;
    position: relative;
    text-align: center;
}

.product-pr {
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 0 20px;
    box-sizing: border-box;
}

h2.product-title {
    width: 100%;
    position: relative;
    margin-bottom: 40px;
    font-size: 4rem;
    color: #000;
}

p.product-message {
    width: 100%;
    position: relative;
    font-size: 1.5rem;
    color: #000;
}

.product-blocks {
    width: 100%;
    max-width: 1200px;
    display: block;
    margin: 0 auto;
    text-align: center;
}

.product-item {
    width: 880px;
    max-width: 100%;
    min-height: 500px;
    display: inline-block;
    position: relative;
    margin: 80px 0;
    background-color: #f6f8f8;
}

.product-item:last-of-type {
    margin-bottom: 160px;
}

.product-block.left {
    width: 50%;
    position: relative;
    float: left;
    padding: 40px;
    box-sizing: border-box;
}

.product-block.right {
    width: 50%;
    position: relative;
    float: right;
    padding: 40px;
    box-sizing: border-box;
}

.product-block.left img.product-img {
    width: 120%;
    display: block;
    position: absolute;
    left: -20%;
}

.product-block.right img.product-img {
    width: 120%;
    display: block;
    position: absolute;
    right: -20%;
}

.product-block img.product-img-resp {
    width: 100%;
    position: relative;
    display: none;
}

h3.product-name {
    width: 100%;
    position: relative;
    font-size: 1.5rem;
    font-weight: bold;
    color: #000;
    margin: 0 0 20px;
}

.product-description {
    width: 100%;
    position: relative;
    margin: 40px 0 0;
    font-size: 1rem;
    color: #000;
    text-align: center;
    border-bottom: 1px solid #000;
    box-sizing: border-box;
}

.product-list {
    text-align: left;
    list-style-type: circle;
    padding-left: 2rem;
    box-sizing: border-box;
}

.product-list p {
    font-size: 1rem;
    color: #000;
    display: list-item;
}

@media screen and (max-width: 1060px) {
    .product-block.left img.product-img {
        width: 100%;
        left: 0;
    }
    
    .product-block.right img.product-img {
        width: 100%;
        right: 0;
    }
}

@media screen and (max-width: 767px) {
    .product-item {
        min-height: auto;
    }

    .product-block.left, .product-block.right {
        width: 100%;
        padding: 40px 30px;
    }

    .product-block.img {
        display: none;
    }

    .product-block img.product-img-resp {
        display: block;
    }
}

@media screen and (max-width: 550px) {
    h2.product-title {
        font-size: 2.5rem;
    }

    p.product-message {
        font-size: 1.25rem;
    }
}