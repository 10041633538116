.header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    padding: 0 5%;
    box-sizing: border-box;
    z-index: 500;
}

.header-content {
    width: 100%;
    position: relative;
}

.header-logo {
    width: 50px;
    position: relative;
    display: block;
    float: left;
    margin-top: 10px;
    transition: .4s;
}

.header-nav-content {
    position: relative;
    float: right;
}