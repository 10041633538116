#popup-root .popup-content {
    width: auto;
    background-color: transparent;
    padding: 0;
    border: none;
}

.contact-popup {
    width: 60px;
    height: 60px;
    padding: 10px;
    border-radius: 50%;
    position: fixed;
    bottom: 6%;
    right: 3%;
    background-color: #494D82;
    z-index: 300;
}

.contact-popup-close {
    display: inline-block;
    position: relative;
    padding: 5px 10px;
    margin-top: 40px;
    border: 2px solid #000;
    border-radius: 20px;
    background-color: #000;
    color: #fff;
    text-align: center;
    font-size: .85rem;
    line-height: 1;
}

.contact-popup-content {
    padding: 20px 40px;
    text-align: center;
    background-color: #fff;
    border: 4px solid #000;
    border-radius: 15px;
    box-sizing: border-box;
}

h3.contact-popup-title {
    font-size: 1.25rem;
    line-height: 1;
    margin: 0 0 20px;
}

.contact-popup-number {
    font-size: 1rem;
    line-height: 1;
    color: #000;
    margin: 0 0 10px;
}

.contact-popup-number a {
    color: #000;
}

.contact-popup-message {
    font-size: 1rem;
    line-height: 1;
    color: #000;
    margin: 0 0 10px;
}

.contact-popup-email {
    font-size: 1rem;
    line-height: 1;
    color: #000;
}

.contact-popup-email a {
    color: #000;
}