.tslider-top {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    padding: 0;
    overflow: hidden;
}

.tslider-bottom {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    display: block;
    padding: 0;
    overflow: hidden;
}

_:-ms-lang(x)::-ms-backdrop,
.slider {
  display: -ms-grid;
  overflow: hidden;
}

.text-slider {
    display: flex;
    overflow: hidden;
}

.slider-text {
    display: flex;
    list-style: none;
}

.slider-text:first-of-type {
    animation: tloop 50s -25s linear infinite;
}

.slider-text:last-of-type {
    animation: tloop2 50s -50s linear infinite;
}

.slider-text > span {
    width: calc(100vw / 4);
    min-width: 200px;
    font-size: 1rem;
    line-height: 1.75;
    color: #fff
}

@keyframes tloop {
    0% {
      transform: translateX(100%);
    }
    to {
      transform: translateX(-100%);
    }
  }
  
  @keyframes tloop2 {
    0% {
      transform: translateX(0);
    }
    to {
      transform: translateX(-200%);
    }
  }

@media screen and (max-width: 300px) {
  .slider-text > span {
    font-size: 1rem;
  }
}