#popup-root .popup-content {
    width: auto;
    background-color: transparent;
    padding: 0;
    border: none;
}

.interior-item a {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.interior-popup-close {
    width: 25px;
    height: 25px;
    display: block;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
    border: 2px solid #000;
    text-align: center;
    font-size: 1.5rem;
    line-height: .8;
    box-sizing: border-box;
}

.interior-popup-content {
    width: 100%;
    height: 100%;
    padding: 20px;
    background-color: #fff;
    text-align: center;
    box-sizing: border-box;
}