@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@200;300;400;500;600;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=M+PLUS+1p:wght@100;300;400;500;700;800;900&display=swap');

.wrapper {
  width: 100%;
  position: relative;
  display: block;
  margin: 0 auto;
  padding: 0;
  text-align: center;
  font-family: 'M PLUS 1p', sans-serif;
  font-size: 16px;
  line-height: 1.75;
  color: #000;
  background-color: #d2d2d7;
}

.bg-logo {
  width: 30%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url('./templates/img/bglogo.svg');
  background-size: cover;
  opacity: .3;
}

.bg-logo::before {
  content: '';
  padding-top: 134%;
  display: block;
}

img {
  width: 100%;
  position: relative;
  margin: 0;
  padding: 0;
}

a {
  cursor: pointer;
  text-decoration: none;
}

.clear {
  content: '';
  display: block;
  clear: both;
}

.divider {
  width: 6%;
  height: 2px;
  position: relative;
  margin: 40px 47%;
  background-color: #000;
}