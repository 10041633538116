.footer {
    width: 100%;
    position: relative;
    display: block;
    float: left;
    background-color: #f6f8f8;
    z-index: 300;
}

.footer-area {
    width: 100%;
    position: relative;
    padding: 40px 20px;
    text-align: center;
    box-sizing: border-box;
}

.up-arrow {
    width: 45px;
    height: 45px;
    position: relative;
    display: inline-block;
    border: 2px solid #000;
    border-radius: 50%;
    background-color: transparent;
    box-sizing: border-box;
}

.up-arrow:hover {
    background-color: rgba(255, 255, 255, .2);
}

.uparrow-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.footer-menu {
    width: 100%;
    max-width: 420px;
    display: inline-block;
    position: relative;
    margin-top: 40px;
}

.footer-menu-block {
    width: 50%;
    position: relative;
    float: left;
    text-align: center;
}

.footer-menu-list {
    width: 100%;
    display: block;
    position: relative;
}

.footer-menu-item {
    display: inline-block;
    position: relative;
    margin: 10px 0;
    font-size: 1.15rem;
    line-height: 30px;
}

.footer-nd-icon {
    width: 25px;
    height: 25px;
    position: relative;
    display: inline-block;
    margin-right: 5px;
    background-color: #fff;
    border-radius: 50%;
}

.footer-sdgs {
    width: 100%;
    display: inline-block;
    position: relative;
    margin-top: 20px;
    text-align: center;
    line-height: 0;
}

h4.footer-sdgs-title {
    position: relative;
    margin: 0 0 10px;
    font-size: 1.15rem;
    font-weight: bold;
    line-height: 1;
    text-align: center;
}

.footer-sdgs-icon {
    width: 35px;
    display: inline-block;
    position: relative;
    margin: 10px 5px 0;
}

.footer-socials {
    width: 100%;
    position: relative;
    margin-top: 20px;
    text-align: center;
    line-height: 0;
}

.footer-social {
    display: inline-block;
    position: relative;
    margin: 0 10px;
}

.copyright {
    width: 100%;
    position: relative;
    display: inline-block;
    font-size: 1rem;
    color: #000;
    margin-top: 2rem;
}

@media screen and (max-width: 500px) {
    .footer-menu-block {
        width: 100%;
    }
}

.footer-nd {
    display: inline-block;
    text-shadow: -0.08em 0.08em #0abab5;
}