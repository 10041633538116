p.radius-text {
    font-size: 1.5rem;
    text-align: center;
    color: #000;
}

.radius-block {
    width: 100%;
    position: relative;
    display: inline-block;
    margin: 40px 0;
    font-size: 0;
    line-height: 0;
    text-align: center;
}

.radius-block h4 {
    font-size: 1.25rem;
    line-height: 1;
    border-bottom: 8px solid #ddd;
    margin: 0 auto;
    width: 100%;
}

.radius-inner {
    display: inline-block;
    padding: 0 5px;
    vertical-align: top;
    box-sizing: border-box;
}

table {
    font-size: 1rem;
    line-height: 1.25;
}
  
table.radius-1 {
    border-collapse: collapse;
    border-spacing: 0;
    text-align: left;
    line-height: 1;
    border: 1px solid #333;
    width: 100%;
    margin: 20px auto;
    box-sizing: border-box;
}
  
table.radius-1 th {
    border-collapse: collapse;
    width: auto;
    padding: 10px;
    font-weight: bold;
    color: #FFF;
    text-align: center;
    vertical-align: middle;
    border: 1px solid #333;
    background: #494D82;
    box-sizing: border-box;
}
  
table.radius-1 td {
    border-collapse: collapse;
    width: auto;
    padding: 10px;
    text-align: center;
    vertical-align: middle;
    border: 1px solid #333;
    box-sizing: border-box;
}
  
table.radius-2 {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 1px;
    text-align: center;
    line-height: 1;
    margin: 20px auto;
    border: 1px solid #333;
    box-sizing: border-box;
}
  
table.radius-2 th {
    border-collapse: collapse;
    width: auto;
    padding: 10px;
    font-weight: bold;
    vertical-align: middle;
    color: #FFF;
    background: #494D82;
    border: 1px solid #333;
    box-sizing: border-box;
}
  
table.radius-2 td {
    border-collapse: collapse;
    width: auto;
    padding: 10px;
    vertical-align: top;
    border: 1px solid #333;
    box-sizing: border-box;
}
  
table.radius-2 th.radius-img, table.radius-2 th.material {
    background-color: #FFF;
}
  
table.radius-2 th.material {
    color: #333;
}
  
.radius img {
    width: 100%;
    border-radius: 5%;
}
  
.radius-block-1 {
    height: 468px;
}
  
.radius-img-1 {
    width: 350px;
    margin: auto;
    position: absolute;
    right: 10%;
    bottom: 40px;
}
  
.radius-block-2 {
    height: 330px;
}
  
.radius-img-2 {
    width: 300px;
    margin: auto;
    position: absolute;
    left: 10%;
    bottom: 30px;
}
  
.radius-block-3 {
    height: 525px;
}
  
.radius-img-3 {
    width: 380px;
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 20px;
}
  
.radius-block-4 {
    height: 472px;
}
  
.radius-img-4 img {
    width: auto;
    margin: auto;
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
}
  
.radius-block-5 {
    height: 356px;
}
  
.radius-img-5 img {
    width: auto;
    margin: auto;
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
}
  
.radius-block-6 {
    height: 682px;
}
  
.radius-img-6-1 {
    width: 200px;
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 120px;
}
  
.radius-img-6-2 {
    width: 100%;
    margin: auto;
    position: absolute;
    left: 0;
    bottom: 20px;
}
  
.radius-block-7 {
    height: 694px;
    margin-bottom: 80px;
}
  
.radius-img-7 {
    width: 300px;
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 80px;
}
  
@media screen and (max-width: 420px) {
.radius-block-1, .radius-block-2, .radius-block-3, .radius-block-6, .radius-block-7 {
      display: none;
}
.radius-block-4, .radius-block-5 {
      height: auto;
}
.radius-img-4 img, .radius-img-5 img {
      position: relative;
      top: auto;
      bottom: auto;
      left: auto;
      right: auto;
}
}
  
@media screen and (min-width: 421px) and (max-width: 767px) {
.radius-block-1, .radius-block-2, .radius-block-3, .radius-block-6, .radius-block-7 {
      display: none;
}
.radius-block-4, .radius-block-5 {
      height: auto;
}
.radius-img-4 img, .radius-img-5 img {
      position: relative;
      top: auto;
      bottom: auto;
      left: auto;
      right: auto;
}
}

/*------------- Width ------------- */

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12 {
    position: relative;
    min-height: 1px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
}
  
.col-1 {
    width: 8.3333333333%;
}
  
.col-2 {
    width: 16.6666666667%;
}
  
.col-3 {
    width: 25%;
}
  
.col-4 {
    width: 33.3333333333%;
}
  
.col-5 {
    width: 41.6666666667%;
}
  
.col-6 {
    width: 50%;
}
  
.col-7 {
    width: 58.3333333333%;
}
  
.col-8 {
    width: 66.6666666667%;
}
  
.col-9 {
    width: 75%;
}
  
.col-10 {
    width: 83.3333333333%;
}
  
.col-11 {
    width: 91.6666666667%;
}
  
.col-12 {
    width: 100%;
}
  
@media screen and (max-width: 767px) {
  .col-1 {
      width: 100%;
  }
  .col-2 {
      width: 100%;
  }
  .col-3 {
      width: 100%;
  }
  .col-4 {
      width: 100%;
  }
  .col-5 {
      width: 100%;
  }
  .col-6 {
      width: 100%;
  }
  .col-7 {
      width: 100%;
  }
  .col-8 {
      width: 100%;
  }
  .col-9 {
      width: 100%;
  }
  .col-10 {
      width: 100%;
  }
  .col-11 {
      width: 100%;
  }
  .col-12 {
      width: 100%;
  }
}
  
@media screen and (min-width: 768px) and (max-width: 991px) {
  .col-1 {
      width: 8.3333333333%;
  }
  .col-2 {
      width: 16.6666666667%;
  }
  .col-3 {
      width: 25%;
  }
  .col-4 {
      width: 33.3333333333%;
  }
  .col-5 {
      width: 41.6666666667%;
  }
  .col-6 {
      width: 50%;
  }
  .col-7 {
      width: 58.3333333333%;
  }
  .col-8 {
      width: 66.6666666667%;
  }
  .col-9 {
      width: 75%;
  }
  .col-10 {
      width: 83.3333333333%;
  }
  .col-11 {
      width: 91.6666666667%;
  }
  .col-12 {
      width: 100%;
  }
}
  
@media screen and (min-width: 992px) and (max-width: 1200px) {
  .col-1 {
      width: 8.3333333333%;
  }
  .col-2 {
      width: 16.6666666667%;
  }
  .col-3 {
      width: 25%;
  }
  .col-4 {
      width: 33.3333333333%;
  }
  .col-5 {
      width: 41.6666666667%;
  }
  .col-6 {
      width: 50%;
  }
  .col-7 {
      width: 58.3333333333%;
  }
  .col-8 {
      width: 66.6666666667%;
  }
  .col-9 {
      width: 75%;
  }
  .col-10 {
      width: 83.3333333333%;
  }
  .col-11 {
      width: 91.6666666667%;
  }
  .col-12 {
      width: 100%;
  }
}