.machine-top {
    width: 100%;
    height: 100vh;
    position: relative;
    text-align: center;
}

.machine-pr {
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 0 20px;
    box-sizing: border-box;
}

h2.machine-title {
    width: 100%;
    position: relative;
    margin-bottom: 40px;
    font-size: 4rem;
    color: #000;
}

p.machine-message {
    width: 100%;
    position: relative;
    font-size: 1.5rem;
    color: #000;
}

.machine-blocks {
    width: 100%;
    max-width: 1200px;
    display: block;
    margin: 0 auto;
    text-align: center;
}

.machine-item {
    width: 880px;
    max-width: 100%;
    min-height: 500px;
    display: inline-block;
    position: relative;
    margin: 80px 0;
    background-color: #f6f8f8;
}

.machine-item:last-of-type {
    margin-bottom: 160px;
}

.machine-block.left {
    width: 50%;
    position: relative;
    float: left;
    padding: 40px;
    box-sizing: border-box;
}

.machine-block.right {
    width: 50%;
    position: relative;
    float: right;
    padding: 40px;
    box-sizing: border-box;
}

.machine-block.left img.machine-img {
    width: 120%;
    display: block;
    position: absolute;
    left: -20%;
}

.machine-block.right img.machine-img {
    width: 120%;
    display: block;
    position: absolute;
    right: -20%;
}

.machine-block img.machine-img-resp {
    width: 100%;
    position: relative;
    display: none;
}

h3.machine-name {
    width: 100%;
    position: relative;
    font-size: 1.5rem;
    color: #000;
    margin: 0 0 20px;
}

.machine-description {
    width: 100%;
    position: relative;
    margin: 40px 0 0;
    font-size: 1rem;
    color: #000;
    text-align: center;
    box-sizing: border-box;
}

table.machine-1 {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    text-align: center;
    line-height: 1;
    border: 1px solid #333;
    margin: 20px auto;
    box-sizing: border-box;
}
  
table.machine-1 th {
    border-collapse: collapse;
    width: auto;
    padding: 10px;
    font-weight: bold;
    color: #FFF;
    text-align: center;
    vertical-align: top;
    border: 1px solid #333;
    background: #494D82;
    box-sizing: border-box;
}
  
table.machine-1 td {
    border-collapse: collapse;
    width: auto;
    padding: 10px;
    vertical-align: top;
    border: 1px solid #333;
    box-sizing: border-box;
}
  
table.machine-2 {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 1px;
    text-align: center;
    line-height: 1;
    margin: 20px auto;
    border: 1px solid #333;
    box-sizing: border-box;
}
  
table.machine-2 th {
    border-collapse: collapse;
    width: auto;
    padding: 10px;
    font-weight: bold;
    vertical-align: top;
    color: #FFF;
    background: #494D82;
    border: 1px solid #333;
    box-sizing: border-box;
}
  
table.machine-2 td {
    border-collapse: collapse;
    width: auto;
    padding: 10px;
    vertical-align: top;
    border: 1px solid #333;
    box-sizing: border-box;
}

.machine-list {
    text-align: left;
    list-style-type: circle;
    padding-left: 2rem;
    box-sizing: border-box;
}

.machine-list p {
    font-size: 1rem;
    color: #000;
    display: list-item;
}

@media screen and (max-width: 1060px) {
    .machine-block.left img.machine-img {
        width: 100%;
        left: 0;
    }
    
    .machine-block.right img.machine-img {
        width: 100%;
        right: 0;
    }
}

@media screen and (max-width: 767px) {
    .machine-item {
        min-height: auto;
    }

    .machine-block.left, .machine-block.right {
        width: 100%;
        padding: 40px 30px;
    }

    .machine-block.img {
        display: none;
    }

    .machine-block img.machine-img-resp {
        display: block;
    }

    .machine-list {
        margin-top: 20px;
    }
}

@media screen and (max-width: 550px) {
    h2.machine-title {
        font-size: 2.5rem;
    }

    p.machine-message {
        font-size: 1.25rem;
    }
}