.navigation {
    position: relative;
    display: block;
    margin: 18px 0 18px 10px;
}

.navigation .nav-opener, .navigation .nav-closer {
    position: relative;
    display: block;
    padding: 5px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 550;
}

.navigation .nav-opener {
    background-color: #494D82;
}

.navigation ul {
    width: 100%;
    height: 100%;
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    padding: 80px 20px;
    margin: 0;
    background-color: #000;
    box-sizing: border-box;
}

.navigation ul li {
    width: 100%;
    display: inline-block;
    font-size: 1.143rem;
    font-weight: 900;
    line-height: 1.215;
    text-align: center;
    margin-bottom: 1.625rem;
}

.nav-link {
    display: inline-block;
    color: #fff;
    text-align: left;
    margin-left: 10px;
    vertical-align: middle;
}

.nav-sdgs-icon {
    display: inline-block;
    width: 1.55rem;
    vertical-align: middle;
    margin-right: -6px;
}

span.nav-text {
    display: inline-block;
    color: #fff;
    text-align: left;
    margin-left: 10px;
    vertical-align: middle;
}

.facebook-icon {
    display: inline-block;
}