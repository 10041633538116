.hero {
    width: 100%;
    height: calc(20800px + 100vh);
    position: relative;
    display: block;
    margin: 0 auto;
    background-color: #000;
}

.hero-bg {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-image: url('../img/hero.jpg');
    background-size: cover;
    z-index: 10;
}

.hero-bg-filter {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .5);
}

.hero-title-bg {
    width: 100%;
    position: fixed;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    text-align: center;
    z-index: 20;
}

h1.hero-title {
    position: relative;
    margin-bottom: 40px;
    font-family: 'Noto Serif JP', serif;
    font-size: 4rem;
    font-weight: bold;
    color: #ffd700;
    margin-bottom: 40px;
}

p.award {
    font-size: 1.5rem;
    color: #fff;
}

p.award br {
    display: none;
}

.down-arrow {
    width: 70px;
    height: 70px;
    position: fixed;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
    border: 1px solid #fff;
    border-radius: 50%;
    box-sizing: border-box;
    z-index: 200;
}

.down-icon {
    padding: 5px;
}

.skip {
    width: 70px;
    height: 70px;
    display: block;
    position: relative;
    border-radius: 50%;
    font-size: 1rem;
    line-height: 70px;
    color: #fff;
    
}

.section {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
}

.section-overlay {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .5);
}

.section-img1 {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    transform: scale(0);
}

.section-img1 .top-video {
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.section-text1 {
    width: 100%;
    max-width: 470px;
    position: fixed;
    bottom: 35vh;
    right: 2%;
    opacity: 0;
}

.section-img2 {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
}

.section-text2 {
    width: 100%;
    max-width: 470px;
    position: fixed;
    top: 35vh;
    left: 10%;
    font-size: 2.35rem;
    opacity: 0;
}

.section-img3 {
    width: 100%;
    height: 100%;
    position: fixed;
    opacity: 0;
}

.section-img3-left {
    width: 50%;
    height: 100%;
    position: relative;
    float: left;
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    animation: proLeft 10s infinite;
}

@keyframes proLeft {
    0% {
        background-image: url('../img/pro-l1.jpg');
    }
    15% {
        background-image: url('../img/pro-l2.jpg');
    }
    20% {
        background-image: url('../img/pro-l2.jpg');
    }
    35% {
        background-image: url('../img/pro-l3.jpg');
    }
    40% {
        background-image: url('../img/pro-l3.jpg');
    }
    55% {
        background-image: url('../img/pro-l4.jpg');
    }
    60% {
        background-image: url('../img/pro-l4.jpg');
    }
    75% {
        background-image: url('../img/pro-l5.jpg');
    }
    80% {
        background-image: url('../img/pro-l5.jpg');
    }
    95% {
        background-image: url('../img/pro-l1.jpg');
    }
    100% {
        background-image: url('../img/pro-l1.jpg');
    }
}

.section-img3-right {
    width: 50%;
    height: 100%;
    position: relative;
    float: right;
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    animation: proRight 10s infinite;
}

@keyframes proRight {
    0% {
        background-image: url('../img/pro-r1.jpg');
    }
    15% {
        background-image: url('../img/pro-r2.jpg');
    }
    20% {
        background-image: url('../img/pro-r2.jpg');
    }
    35% {
        background-image: url('../img/pro-r3.jpg');
    }
    40% {
        background-image: url('../img/pro-r3.jpg');
    }
    55% {
        background-image: url('../img/pro-r4.jpg');
    }
    60% {
        background-image: url('../img/pro-r4.jpg');
    }
    75% {
        background-image: url('../img/pro-r5.jpg');
    }
    80% {
        background-image: url('../img/pro-r5.jpg');
    }
    95% {
        background-image: url('../img/pro-r1.jpg');
    }
    100% {
        background-image: url('../img/pro-r1.jpg');
    }
}

.section-img3-overlay {
    width: 100%;
    position: fixed;
    top: 0;
    background-color: #000;
    z-index: 30;
}

.section-text3 {
    width: 100%;
    max-width: 470px;
    position: fixed;
    bottom: 35vh;
    right: 2%;
    opacity: 0;
}

.section-title {
    position: relative;
    font-family: 'Noto Serif JP', serif;
    font-size: 2.35rem;
    color: #fff;
    text-align: left;
}

.section-list {
    text-align: left;
    list-style-type: circle;
    padding-left: 2rem;
    box-sizing: border-box;
}

.section-list p {
    font-size: 1rem;
    color: #fff;
    display: list-item;
}

.section-description {
    text-align: left;
    box-sizing: border-box;
}

.section-description p {
    font-size: 1rem;
    color: #fff;
}

.hero-message-outer {
    width: 100%;
    position: fixed;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    text-align: center;
    opacity: 0;
}

h2.hero-message {
    position: relative;
    font-family: 'Noto Serif JP', serif;
    font-size: 5rem;
    color: #ffd700;
}

.hero-resp {
    width: 100%;
    height: 100vh;
    display: none;
    position: relative;
}

.hero-resp .hero-bg, .hero-resp .hero-title-bg {
    position: absolute;
}

@media screen and (max-width: 830px) {
    p.award br {
        display: block;
    }
}

@media screen and (max-width: 767px) {
    .hero {
        display: none;
    }
    .hero-resp {
        display: block;
    }
}

@media screen and (max-width: 650px) {
    h1.hero-title {
        font-size: 2.5rem;
    }

    p.award {
        font-size: 1rem;
    }
}

@media screen and (max-width: 420px) {
    p.award {
        font-size: .85rem;
    }
}